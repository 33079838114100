import { Chart as ChartJS } from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';

import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import {
  useGetTeamAggregateWorkDataActivityQuery,
  useGetTeamAggregateWorkDataSpecialityQuery,
} from '../../slices/worklistsApiSlice.js';
import { FaLayerGroup } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useGetUsersManagerQuery } from '../../slices/usersApiSlice.js';

import '../../utils/tableStack.css';
//import Message from './Message.jsx';
//import Loader from './Loader.jsx';

// Register the plugins to all charts:
ChartJS.register(TreemapController, TreemapElement);

function ManagerReports() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const [chartInstances, setChartInstances] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const myUserId = userInfo._id;
  const teamId = userInfo._id;

  //const { data, worklistId, theheading } = location.state || {};
  const chartRefs = useRef([]);
  chartRefs.current = Array(9)
    .fill()
    .map((_, i) => chartRefs.current[i] || React.createRef());

  const [isChartReady, setIsChartReady] = useState(false);

  const {
    data: teamAggregateWorkDataTreeMap = [],
    isLoading,
    error,
    refetch,
  } = useGetTeamAggregateWorkDataActivityQuery(teamId);

  const {
    data: teamAggregateWorkDataTreeMapSpeciality = [],
    isLoading: isLoadingsp,
    error: errorsp,
    refetch: refetchsp,
  } = useGetTeamAggregateWorkDataSpecialityQuery(teamId);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    chartInstances.forEach((instance) => instance.destroy());
    setChartInstances([]);
    setShow(false);
  };

  console.log('TreemapChart2 : data', teamAggregateWorkDataTreeMap);

  console.log('TreemapChart2 : data', teamAggregateWorkDataTreeMapSpeciality);

  console.log('TreemapChart2  userInfo', userInfo);

  console.log('TreeMap WorkVolumeChart : userId', myUserId);
  // ${theheading}

  const {
    data: users,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useGetUsersManagerQuery(userInfo._id);

  function getRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  }

  useEffect(() => {
    return () => {
      chartRefs.current.forEach((ref) => {
        const existingChart = ChartJS.getChart(ref.current);
        if (existingChart) {
          existingChart.destroy();
        }
      });
    };
  }, []);

  useEffect(() => {
    if (chartRefs.current.every((ref) => ref.current)) {
      const instances = chartRefs.current.map((ref, index) => {
        //______________________________________________________________________________________
        if (index === 0) {
          const totalWorkTime = teamAggregateWorkDataTreeMap
            .reduce((acc, item) => acc + item.theWorkTime, 0)
            .toFixed(2);

          const Staff_No = (users || []).reduce((sum, user) => sum + 1, 0);

          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 20px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Staff: ${Staff_No}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          return new ChartJS(ref.current, {
            type: 'bar',
            onClick: (event) => {
              const chart = ChartJS.getChart(ref.current);
              if (chart) {
                console.log('Chart clicked!'); // Debugging event
                const elements = chart.getElementsAtEventForMode(
                  event,
                  'nearest',
                  { intersect: true },
                  false
                );

                if (elements.length > 0) {
                  const { index } = elements[0];
                  console.log(`Clicked on index: ${index}`);
                  if (index === 0) {
                    console.log('Redirecting to /manager/reports/List');
                    navigate('/manager/reports/List');
                  }
                } else {
                  console.log('No elements detected at click position.');
                }
              } else {
                console.log('Chart instance not found.');
              }
            },

            data: {
              labels: ['Total Staff'],
              datasets: [
                {
                  label: `Staff - ${userInfo.directorateName}`,
                  data: [totalWorkTime],
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  if (index === 0) {
                    navigate('/manager/reports/List');
                  }
                } else {
                  console.log('No elements detected at click position.');
                }
              },

              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                totalWorkTimePlugin: {},
              },
              // Set a custom background color for the chart's canvas element
              elements: {
                rectangle: {
                  backgroundColor: 'rgba(200, 200, 200, 0.1)', // Optional background for bars
                },
              },
            },
            plugins: [totalWorkTimePlugin],

            // Apply the gray background, border, and rounded corners to the chart container
            chartAreaBackgroundColor: '#f0f0f0', // Light gray background
            chartAreaBorderColor: '#ccc', // Gray border
            chartAreaBorderWidth: 2,
            chartAreaBorderRadius: 20, // Rounded edges for the border
          });
        }
        //______________________________________________________________________________________________________________________________________
        else if (index === 1) {
          // Calculate the total work time and format to 2 decimal places
          const totalWorkTime = teamAggregateWorkDataTreeMap
            .reduce((acc, item) => acc + item.theWorkTime, 0)
            .toFixed(2);

          // Define a custom plugin to draw the text
          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 20px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Weekly Hours: ${totalWorkTime}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          // Bar chart for the second chart
          return new ChartJS(ref.current, {
            type: 'bar',
            data: {
              labels: ['Weekly Hours'],
              datasets: [
                {
                  label: `Weekly Hours - ${userInfo.directorateName}`,
                  data: [totalWorkTime],
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },

              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                totalWorkTimePlugin: {}, // Include the custom plugin
              },
            },
            plugins: [totalWorkTimePlugin], // Register the custom plugin
          });
        }
        //______________________________________________________________________________________________________________________
        else if (index === 2) {
          // Group users by speciality and count locum/non-locum users
          const specialityCounts = (users || []).reduce((acc, user) => {
            const { speciality, isLocum } = user;
            if (!acc[speciality]) {
              acc[speciality] = { locum: 0, regular: 0 };
            }
            if (isLocum) {
              acc[speciality].locum += 1;
            } else {
              acc[speciality].regular += 1;
            }
            return acc;
          }, {});

          const specialities = Object.keys(specialityCounts); // Speciality labels
          const locumCounts = specialities.map(
            (speciality) => specialityCounts[speciality].locum
          );
          const regularCounts = specialities.map(
            (speciality) => specialityCounts[speciality].regular
          );

          // Calculate total counts
          const totalRegular = regularCounts.reduce(
            (sum, count) => sum + count,
            0
          );
          const totalLocum = locumCounts.reduce((sum, count) => sum + count, 0);
          const totalStaff = totalRegular + totalLocum;

          // Calculate percentages
          const regularPercentage = ((totalRegular / totalStaff) * 100).toFixed(
            1
          );
          const locumPercentage = ((totalLocum / totalStaff) * 100).toFixed(1);

          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 8px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Specialities: ${specialities.length}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          return new ChartJS(ref.current, {
            type: 'bar',

            onClick: (event) => {
              const chart = ChartJS.getChart(ref.current);
              if (chart) {
                const elements = chart.getElementsAtEventForMode(
                  event,
                  'nearest',
                  { intersect: true },
                  false
                );

                if (elements.length > 0) {
                  const { index } = elements[0];
                  console.log(`Clicked on speciality: ${specialities[index]}`);
                  // Add navigation or actions based on speciality if needed
                } else {
                  console.log('No elements detected at click position.');
                }
              } else {
                console.log('Chart instance not found.');
              }
            },

            data: {
              labels: specialities, // Speciality labels
              datasets: [
                {
                  label: `Regular Staff (${totalRegular}),(${regularPercentage}%)`,
                  data: regularCounts, // Data for regular staff
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
                {
                  label: `Locum Staff (${totalLocum}), (${locumPercentage}%)`,
                  data: locumCounts, // Data for locum staff
                  backgroundColor: 'rgba(192, 75, 75, 0.6)',
                  borderColor: 'rgba(192, 75, 75, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true, // Enable stacking on the y-axis
                },
                x: {
                  stacked: true, // Enable stacking on the x-axis
                  ticks: {
                    font: {
                      size: 10, // Set the font size for the x-axis labels (Speciality labels)
                      family: 'Arial', // Optionally, you can change the font family
                      weight: 'bold', // Optionally, change font weight
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                totalWorkTimePlugin: {},
              },
            },
            plugins: [totalWorkTimePlugin],
          });
        }

        //_______________________________________________________________________________________________________________________
        else if (index === 3) {
          // Ensure users is defined and is an array
          if (!Array.isArray(users) || users.length === 0) {
            console.warn('No user data available.');
            return;
          }

          // Group users by contracttype and buckleycontract
          const contractData = users.reduce((acc, user) => {
            const { buckleycontract, contracttype } = user || {};
            if (!contracttype || !buckleycontract) {
              console.warn('Invalid user data detected:', user);
              return acc;
            }

            if (!acc[contracttype]) {
              acc[contracttype] = {};
            }
            if (!acc[contracttype][buckleycontract]) {
              acc[contracttype][buckleycontract] = 0;
            }
            acc[contracttype][buckleycontract] += 1;
            return acc;
          }, {});

          const contractTypes = Object.keys(contractData); // Unique contracttype labels
          const buckleyContracts = Array.from(
            new Set(users.map((user) => user.buckleycontract).filter(Boolean))
          ); // Unique buckleycontract labels

          // Prepare datasets for each buckleycontract (now stacked by buckleycontract)
          const datasets = buckleyContracts.map((buckleycontract) => {
            const data = contractTypes.map(
              (contracttype) => contractData[contracttype][buckleycontract] || 0
            );
            return {
              label: `${buckleycontract}`,
              data,
              backgroundColor: getRandomColor(),
              borderColor: 'rgba(0, 0, 0, 0.5)',
              borderWidth: 1,
            };
          });

          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 12px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Contract Types: ${contractTypes.length}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          return new ChartJS(ref.current, {
            type: 'bar',
            data: {
              labels: contractTypes, // contracttype labels
              datasets, // Datasets for each buckleycontract
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true, // Stack the data on the y-axis
                },
                x: {
                  stacked: true, // Enable stacking on the x-axis
                  ticks: {
                    font: {
                      size: 10, // Set the font size for the x-axis labels (Speciality labels)
                      family: 'Arial', // Optionally, you can change the font family
                      weight: 'bold', // Optionally, change font weight
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      size: 8, // Change the font size for the legend labels
                    },
                  },
                },
                totalWorkTimePlugin: {},
              },
            },
            plugins: [totalWorkTimePlugin],
          });
        }

        //_______________________________________________________________________________________________________________________
        else if (index === 4) {
          // Group users by speciality and count approvedtrainer/non-approvedtrainer users
          const specialityCounts = (users || []).reduce((acc, user) => {
            const { speciality, approvedtrainer } = user;
            if (!acc[speciality]) {
              acc[speciality] = { approved: 0, notApproved: 0 };
            }
            if (approvedtrainer) {
              acc[speciality].approved += 1;
            } else {
              acc[speciality].notApproved += 1;
            }
            return acc;
          }, {});

          const specialities = Object.keys(specialityCounts); // Speciality labels
          const approvedCounts = specialities.map(
            (speciality) => specialityCounts[speciality].approved
          );
          const notApprovedCounts = specialities.map(
            (speciality) => specialityCounts[speciality].notApproved
          );

          // Calculate total counts
          const totalApproved = approvedCounts.reduce(
            (sum, count) => sum + count,
            0
          );
          const totalNotApproved = notApprovedCounts.reduce(
            (sum, count) => sum + count,
            0
          );
          const totalStaff = totalApproved + totalNotApproved;

          // Calculate percentages
          const approvedPercentage = (
            (totalApproved / totalStaff) *
            100
          ).toFixed(1);
          const notApprovedPercentage = (
            (totalNotApproved / totalStaff) *
            100
          ).toFixed(1);

          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 12px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Specialities: ${specialities.length}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          return new ChartJS(ref.current, {
            type: 'bar',

            onClick: (event) => {
              const chart = ChartJS.getChart(ref.current);
              if (chart) {
                const elements = chart.getElementsAtEventForMode(
                  event,
                  'nearest',
                  { intersect: true },
                  false
                );

                if (elements.length > 0) {
                  const { index } = elements[0];
                  console.log(`Clicked on speciality: ${specialities[index]}`);
                  // Add navigation or actions based on speciality if needed
                } else {
                  console.log('No elements detected at click position.');
                }
              } else {
                console.log('Chart instance not found.');
              }
            },

            data: {
              labels: specialities, // Speciality labels
              datasets: [
                {
                  label: `Approved Trainers (${totalApproved}),(${approvedPercentage}%)`,
                  data: approvedCounts, // Data for approved trainers
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
                {
                  label: `Not Approved Trainers (${totalNotApproved}),(${notApprovedPercentage}%)`,
                  data: notApprovedCounts, // Data for not approved trainers
                  backgroundColor: 'rgba(192, 75, 75, 0.6)',
                  borderColor: 'rgba(192, 75, 75, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true, // Enable stacking on the y-axis
                },
                x: {
                  stacked: true, // Enable stacking on the x-axis
                  ticks: {
                    font: {
                      size: 10, // Set the font size for the x-axis labels (Speciality labels)
                      family: 'Arial', // Optionally, you can change the font family
                      weight: 'bold', // Optionally, change font weight
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                totalWorkTimePlugin: {},
              },
            },
            plugins: [totalWorkTimePlugin],
          });
        }

        //________________________________________________________________________________________________________________________
        else if (index === 5) {
          // Bar chart for the second chart
          return new ChartJS(ref.current, {
            type: 'treemap',
            data: {
              datasets: [
                {
                  label: `Team Speciality Chart for - ${userInfo.directorateName} - ${userInfo.salutation} ${userInfo.name}`,
                  tree: teamAggregateWorkDataTreeMapSpeciality, //Speciality
                  key: 'theWorkTime',
                  groups: ['speciality'],
                  //groups: ['activity'],

                  spacing: 0.5,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.1)',
                  //backgroundColor: 'rgba(0,123,255,0.5)',
                  backgroundColor: (ctx) => {
                    console.log('backgroundColor: ctx', ctx);

                    if (
                      !ctx ||
                      !ctx.raw ||
                      !ctx.raw._data ||
                      !ctx.raw._data.num
                    ) {
                      return 'rgba(0, 123, 255, 0.4)';
                    }
                    const alpha = (1 + Math.log(ctx.raw._data.num)) / 5;
                    return `rgba(0, 128, 0, ${alpha})`;
                  },
                  hoverBackgroundColor: 'rgba(0,123,255,0.7)',

                  labels: {
                    display: true,
                    formatter: (ctx) => {
                      console.log('ctx inside formatter:', ctx);

                      if (ctx && ctx.raw.g && ctx.raw.v) {
                        return `${ctx.raw.g} - ${ctx.raw.v}`;
                      }
                      return 'blank'; // Return 'blank' if label or num is undefined
                    },
                    color: 'black',
                    font: {
                      size: 12,
                    },
                    align: 'center',
                    anchor: 'center',
                    clip: true,
                    overflow: 'fit',
                  },
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },

              plugins: {
                legend: {
                  display: true,
                },
              },
            },
          });
        } else {
          // Treemap chart for other charts
          return new ChartJS(ref.current, {
            type: 'treemap',
            data: {
              datasets: [
                {
                  label: `Team Activity Chart for - ${userInfo.directorateName} - ${userInfo.salutation} ${userInfo.name}`,
                  tree: teamAggregateWorkDataTreeMap,
                  key: 'theWorkTime',
                  groups: ['activity'],
                  spacing: 0.5,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.1)',
                  //backgroundColor: 'rgba(0,123,255,0.5)',
                  backgroundColor: (ctx) => {
                    console.log('backgroundColor: ctx', ctx);

                    if (
                      !ctx ||
                      !ctx.raw ||
                      !ctx.raw._data ||
                      !ctx.raw._data.num
                    ) {
                      return 'rgba(0, 123, 255, 0.4)';
                    }
                    const alpha = (1 + Math.log(ctx.raw._data.num)) / 5;
                    return `rgba(0, 128, 0, ${alpha})`;
                  },
                  hoverBackgroundColor: 'rgba(0,123,255,0.7)',

                  labels: {
                    display: true,
                    formatter: (ctx) => {
                      console.log('ctx inside formatter:', ctx);

                      if (ctx && ctx.raw.g && ctx.raw.v) {
                        return `${ctx.raw.g} - ${ctx.raw.v}`;
                      }
                      return 'blank'; // Return 'blank' if label or num is undefined
                    },
                    color: 'black',
                    font: {
                      size: 12,
                    },
                    align: 'center',
                    anchor: 'center',
                    clip: true,
                    overflow: 'fit',
                  },
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },
              plugins: {
                legend: {
                  display: true,
                },
              },
            },
          });
        }
      });

      // Indicate that the charts are ready
      setIsChartReady(true);
      setChartInstances(instances);

      return () => {
        instances.forEach((instance) => instance.destroy());
      };
    }
  }, [
    teamAggregateWorkDataTreeMap,
    teamAggregateWorkDataTreeMapSpeciality,
    userInfo.name,
    users,
  ]);

  // Function to handle print action
  const handlePrint = () => {
    if (chartRefs.current.length) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Charts</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  font-family: Arial, sans-serif;
                }
                .chart-container {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr); /* Grid layout for 3 columns */
                  gap: 20px;
                  padding: 20px; /* Padding for better aesthetics */
                  background-color: #f0f0f0; /* Light grey background */
                  border-radius: 10px; /* Rounded corners for the container */
                }
                .chart {
                  background-color: #ffffff; /* White background for each chart */
                  border: 2px solid #ffffff; /* White border around each chart */
                  border-radius: 8px; /* Rounded corners for each chart */
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
                  padding: 10px; /* Padding inside the chart container */
                  page-break-inside: avoid; /* Prevent page break inside a chart */
                }
                .chart img {
                  width: 100%; /* Scale the image to fit within the container */
                  height: auto;
                }
                .chart p {
                  text-align: center;
                  margin-top: 5px;
                }
              }
              /* On-screen styles */
              .chart-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr); /* Grid layout for 3 columns */
                gap: 20px;
                padding: 20px;
                background-color: #f0f0f0;
                border-radius: 10px;
              }
              .chart {
                background-color: #ffffff;
                border: 2px solid #ffffff;
                border-radius: 8px;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                padding: 10px;
              }
              .chart img {
                width: 100%;
                height: auto;
              }
            </style>
          </head>
          <body>
            <div class="chart-container">
      `);

      chartRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const dataUrl = ref.current.toDataURL();
          printWindow.document.write(`
            <div class="chart">
              <img src="${dataUrl}" />
              <p>Chart ${index + 1}</p>
            </div>
          `);
        }
      });

      printWindow.document.write(`
            </div>
            <script>
              window.onload = function() {
                window.print();
                window.close();
              };
            </script>
          </body>
        </html>
      `);

      printWindow.document.close();
    } else {
      alert('No charts available to print.');
    }
  };

  /*
  // Function to handle print action

  const handlePrint = () => {
    if (isChartReady && chartRefs.current.every((ref) => ref.current)) {
      chartRefs.current.forEach((ref) => {
        const canvas = ref.current;
        const dataUrl = canvas.toDataURL();
        const printWindow = window.open('', '_blank');
        printWindow.document.write(
          '<html><head><title>Activity Chart</title></head><body>'
        );
        printWindow.document.write(
          `<img src="${dataUrl}" onload="window.print();window.close()" />`
        );
        printWindow.document.write('</body></html>');
        printWindow.document.close();
      });
    }
  };

  */
  //______________________________________________________________________________________________________

  if (isLoading || isLoadingsp || loadingUsers) {
    return (
      <p>
        <h1>Loading......</h1>
      </p>
    );
  }

  return (
    <>
      <Button variant='outline-secondary' onClick={() => navigate(-1)}>
        Go Back
      </Button>
      <div>
        <h2> {userInfo.directorateName} Activity Charts</h2>
        <div
          className='chart-container'
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', // Default for large screens
            gap: '20px',
            padding: '20px', // Add padding for better aesthetics
            backgroundColor: '#f0f0f0', // Light grey background
            borderRadius: '10px', // Rounded corners for the container
          }}
        >
          {chartRefs.current.map((ref, index) => (
            <div
              key={index}
              style={{
                backgroundColor: '#ffffff', // White background for individual charts
                border: '2px solid #ffffff', // White border around each chart
                borderRadius: '8px', // Rounded corners for each chart
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better aesthetics
                padding: '10px', // Padding inside the chart container
              }}
            >
              <canvas ref={ref} />
            </div>
          ))}
        </div>
      </div>

      <Button variant='outline-primary' size='sm' onClick={handlePrint}>
        Print Charts
      </Button>
    </>
  );
}

export default ManagerReports;
